import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Tabs,
  Grid,
  Tab,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core'

import LoadingButton from '../components/LoadingButton'
import LambdaFetch from '../functions/FetchFromLambda'
import TableMui from './TableMui'
import getTableOptions from '../constants/TableOptions'
import NumberFormat from 'react-number-format'
import { wageCols, deductCols } from '../constants/EmployeeCols'
import { AntTabs, AntTab } from '../components/common/Tabs'
import ReactTable from '../components/ReactTable'

export default function CustomizedTabs (props) {

  const [data, setData] = React.useState(null)
  const [year,setYear] = React.useState(null)
  const [value, setValue] = React.useState(0)
  const [isLoading, setLoading] = React.useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getWageData = async e => {
    e.preventDefault()
    if (!e.target.year.value) return null
    setLoading(true)
    setYear(e.target.year.value)
    const resp = await LambdaFetch(
      'employee-form',
      'post',
      props.accessToken,
      JSON.stringify({
        action: 'wages',
        empId: props.employee.EMPLOYEE,
        year: e.target.year.value,
        company: props.company
      })
    )
    if (resp.success) {
      const { wage, deduction } = resp.data
      
      setData({ wage, deduction })
    }
    setLoading(false)
  }

  const wageOpt = getTableOptions({cols: wageCols, fileName: `${props.fileName}_Wage_${year}`});
  const dedOpt = getTableOptions({cols: deductCols, fileName: `${props.fileName}_Deduction_${year}`});


  let wageData,
    dedData = []

  let wageTotals = {
    hours: 0,
    units: 0,
    wages: 0
  }

  let dedTotals = {
    amount: 0,
    wages: 0
  }
  if (data) {
    wageData = data.wage.map(row => {
      return [
        row.PAY_SUM_GRP,
        row.DESCRIPTION,
        row.HOURS,
        row.sum_units,
        row.sum_wage
      ]
    })
    dedData = data.deduction.map(row => {
      return [
        row.DED_CODE,
        row.description,
        row.PAID_BY,
        row.sum_ded,
        row.sum_wage
      ]
    })

    wageTotals = data.wage.reduce(
      (acc, cur) => {
        let curTots = acc
        curTots.hours += cur.HOURS
        curTots.units += cur.sum_units
        curTots.wages += cur.sum_wage
        return curTots
      },
      {
        hours: 0,
        units: 0,
        wages: 0
      }
    )
    dedTotals = data.deduction.reduce(
      (acc, cur) => {
        let curTots = acc
        if (cur.PAID_BY === 'Company') {
          curTots.company.amount += cur.sum_ded
          curTots.company.wages += cur.sum_wage
        } else if (cur.PAID_BY === 'Employee') {
          curTots.employee.amount += cur.sum_ded
          curTots.employee.wages += cur.sum_wage
        }
        return curTots
      },
      {
        company: {
          amount: 0,
          wages: 0
        },
        employee: {
          amount: 0,
          wages: 0
        }
      }
    )
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={e => getWageData(e)}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div>
                <FormControl variant='outlined'>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Select Year
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    style={{
                      minWidth: '150px',
                      maxHeight: 300,
                      overflowY: 'scroll'
                    }}
                    inputProps={{ id: 'year' }}
                    id='demo-simple-select-outlined'
                    defaultValue={props.years[0] || null}
                    label='Select Year'
                    margin='dense'
                  >
                    {props.years.map(yr => (
                      <MenuItem key={yr} value={yr}>
                        {yr}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ marginLeft: '0.5rem' }}>
                <LoadingButton
                  label='Search'
                  isLoading={isLoading}
                  color='primaryVLButton'
                  buttonType='submit'
                />
              </div>
            </div>
          </form>
          {!!data && (
            <div>
    
              <AntTabs
                color={props.fetchInitialData.credentials.primaryAppColor}
                value={value}
                onChange={handleChange}
                aria-label='ant example'
              >
                <AntTab
                  label='Wage'
                  value={0}
                  color={props.fetchInitialData.credentials.primaryAppColor}
                />
                <AntTab
                  label='Deduction'
                  value={1}
                  color={props.fetchInitialData.credentials.primaryAppColor}
                />
              </AntTabs>
              {value === 0 && (
                <div style={{ position: 'relative', textAlign: 'center' }}>
                  <Paper
                    style={{
                      padding: '0.5rem',
                      margin: '0.5rem 0',
                      display: 'inline-block'
                    }}
                    elevation={0}
                  >
                    <div style={{ textAlign: 'center', margin: 'auto 0.5rem' }}>
                      <div style={{ fontWeight: 600, fontSize: '1.1rem' }}>
                        Totals
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      {statistic(wageTotals.hours, 'Hours', '')}
                      {statistic(wageTotals.units, 'Units', '')}
                      {statistic(wageTotals.wages, 'Wages', '$')}
                    </div>
                  </Paper>
                  <ReactTable cols={wageCols} data={data.wage} />

                  {/* <TableMui
                    cols={wageCols}
                    data={wageData}
                    options={{ ...wageOpt, elevation: 2 }}
                    title=''
                  /> */}
                </div>
              )}
              {value === 1 && (
                <div style={{ position: 'relative', textAlign: 'center' }}>
                  <Paper
                    style={{
                      padding: '0.5rem',
                      margin: '0.5rem 0',
                      display: 'inline-block'
                      // overflowX: 'scroll'
                    }}
                    elevation={0}
                  >
                    <div style={{ textAlign: 'center', margin: 'auto 0.5rem' }}>
                      <div style={{ fontWeight: 600, fontSize: '1.1rem' }}>
                        Totals
                      </div>
                    </div>

                    <div
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      {statistic(dedTotals.company.amount, 'Company', '$')}
                      {statistic(dedTotals.employee.amount, 'Employee', '$')}
                    </div>
                  </Paper>

                  <ReactTable cols={deductCols} data={data.deduction} />

                  {/* <TableMui
                    cols={deductCols}
                    data={dedData}
                    options={{ ...dedOpt, elevation: 2 }}
                    title=''
                  /> */}
                </div>
                
              )}
       
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

function statistic (val, label, prefix) {
  return (
    <div style={{ textAlign: 'center', margin: '0 1rem' }}>
      <div style={{ fontSize: '0.9rem' }}>{label}</div>
      <NumberFormat
        value={(Math.round(val * 100) / 100).toFixed(2)}
        thousandSeparator={true}
        decimalScale={2} 
        displayType='text'
        prefix={prefix}
      />
    </div>
  )
}
